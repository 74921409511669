import '@/styles/globals.css';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { useState } from 'react';
import { SWRConfig } from 'swr';

import RefreshTokenHandler from '@/components/refreshTokenHandler';
import { AlertStoreProvider } from '@/providers/alert-store-provider';
import { NextIntlClientProvider, RichTranslationValues } from 'next-intl';
import { ThemeProvider } from 'next-themes';
import localFont from 'next/font/local';
import { useRouter } from 'next/router';

export const defaultTranslationValues: RichTranslationValues = {
  important: (chunks) => <strong>{chunks}</strong>,
  br: () => <br />,
};

const TeleNeo = localFont({
  src: [
    {
      path: '../../public/fonts/TeleNeoVariable.woff2',
    },
  ],
  variable: '--font-teleneo',
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [interval, setInterval] = useState(0);
  return (
    <SessionProvider session={pageProps.session} refetchInterval={180}>
      <NextIntlClientProvider
        locale={router.locale}
        // TODO: TZ variable
        timeZone="Europe/Berlin"
        messages={pageProps.messages}
        defaultTranslationValues={defaultTranslationValues}
      >
        <ThemeProvider enableSystem={false}>
          <SWRConfig value={{ provider: () => new Map() }}>
            <AlertStoreProvider>
              <div className={`${TeleNeo.variable} font-sans`}>
                <Component {...pageProps} />
              </div>
            </AlertStoreProvider>
          </SWRConfig>
        </ThemeProvider>
        <RefreshTokenHandler setInterval={setInterval} />
      </NextIntlClientProvider>
    </SessionProvider>
  );
}
